import React, { useEffect, useRef, useState } from "react";
import jsQR from "jsqr";
import { useLessons } from "../hooks/useLessons";
import styled from "styled-components";
import { brandOrange } from "./Colors";
import { useUser } from "../hooks/useUser";
import { Button, CircularProgress } from "@material-ui/core";
import { MdCheckCircle } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";
import { Heading3 } from "./Typography";
import { navigate } from "gatsby";

const StyledScanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledHeading = styled(Heading3)`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const StyledCanvas = styled.canvas`
  width: 100%;
  max-width: 640px;
`;

const Scanner = ({ onScan, onReady, lockOutRef }) => {
  const canvasRef = useRef();
  const videoRef = useRef(document.createElement("video"));

  const drawLine = (begin, end, color) => {
    const canvas = canvasRef.current.getContext("2d");
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  };

  const tick = () => {
    const canvasElement = canvasRef.current;
    const video = videoRef.current;

    if (canvasElement && video.readyState === video.HAVE_ENOUGH_DATA) {
      onReady();
      const canvas = canvasElement.getContext("2d");

      canvasElement.hidden = false;

      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      var imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (code) {
        drawLine(
          code.location.topLeftCorner,
          code.location.topRightCorner,
          brandOrange
        );
        drawLine(
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          brandOrange
        );
        drawLine(
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          brandOrange
        );
        drawLine(
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          brandOrange
        );
        if (!lockOutRef.current) {
          onScan(code.data);
          lockOutRef.current = true;
        }
      }
    }
    requestAnimationFrame(tick);
  };

  useEffect(() => {
    const video = videoRef.current;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then(function (stream) {
        video.srcObject = stream;
        video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        video.play();
        requestAnimationFrame(tick);
      });

    return () => {
      video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    };
  }, []);

  return <StyledCanvas ref={canvasRef}></StyledCanvas>;
};

export const Attendance = ({ code }) => {
  const { registerAttendance } = useLessons(null, { autoLoad: false });
  const { isReady: isUserReady } = useUser();
  const [isReady, setIsReady] = useState(false);
  const lockOutRef = useRef(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onCode = async (code) => {
    if (!code) {
      lockOutRef.current = false;
      return;
    }
    const result = await registerAttendance(code);
    if (result) {
      setIsSuccess(true);
    } else {
      setIsError(true);
      lockOutRef.current = false;
    }
  };

  const extractCode = (result) => {
    try {
      const url = new URL(result);
      const params = new URLSearchParams(url.search);
      return params.get("code");
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  useEffect(() => {
    if (isUserReady && code) {
      onCode(code);
    }
  }, [code, isUserReady]);

  if (isSuccess) {
    return (
      <StyledScanner>
        <MdCheckCircle size={300} color="green" />
        <StyledHeading>Dalība reģistrēta!</StyledHeading>
        <Button variant="contained" onClick={() => navigate("/profils")}>
          Doties uz profilu
        </Button>
      </StyledScanner>
    );
  }

  if (isError) {
    return (
      <StyledScanner>
        <IoCloseCircle size={300} color="red" />
        <StyledHeading>Neizdevās reģistrēt dalību!</StyledHeading>
        <Button variant="contained" onClick={() => setIsError(false)}>
          Mēģiāt vēlreiz
        </Button>
      </StyledScanner>
    );
  }

  return (
    <StyledScanner>
      {!isUserReady ? (
        <CircularProgress size={300} />
      ) : (
        <>
          {!isReady ? (
            <CircularProgress size={300} />
          ) : (
            <StyledHeading>Nolasi nodarbības QR kodu</StyledHeading>
          )}
          <Scanner
            lockOutRef={lockOutRef}
            onScan={(data) => onCode(extractCode(data))}
            onReady={() => setIsReady(true)}
          />
        </>
      )}
    </StyledScanner>
  );
};

import { navigate } from "gatsby";
import React from "react";

import { Layout } from "../components/Layout";
import { Attendance } from "../components/Attendance";
import Seo from "../components/SEO";
import { useAuth } from "../hooks/useAuth";

const AttendancePage = () => {
  const { isAuthenticated } = useAuth();

  React.useEffect(() => {
    if (!isAuthenticated) {
      const nextUrl = window.location.pathname + window.location.search;
      navigate(`/ienakt?nextUrl=${nextUrl}`);
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  const code =
    typeof window === "object"
      ? new URLSearchParams(window.location.search).get("code")
      : null;

  return (
    <Layout>
      <Seo title="Apmeklējuma reģistrācija" />
      <Attendance code={code} />
    </Layout>
  );
};

export default AttendancePage;
